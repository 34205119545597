/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import { Navbar, Nav } from 'react-bootstrap';
import { Redirect } from '@reach/router';
import { getUserInfo, isLoggedIn, logout } from '../services/auth';
import './layout.scss';

const Layout = ({ children }) => {
  const content = { message: '', login: true };
  if (isLoggedIn()) {
    content.message = `Hello, ${getUserInfo().username}`;
  } else {
    content.message = 'You are not logged in';
  }
  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md">
        <Link to="/" className="nav-brand text-light">
          {'TEST-DASH'}
        </Link>
        <Nav className="mr-auto">
          <Link to="/coverageDash/" className="nav-link">
            Coverage
          </Link>
        </Nav>
        <nav className="ml-auto text-light">
          <span className="mr-2">{content.message}</span>
          {isLoggedIn() ? (
            <a
              href="/"
              onClick={event => {
                event.preventDefault();
                logout(() => navigate(`/login`));
              }}
            >
              Logout
            </a>
          ) : (
            <Link to="/login">log in</Link>
          )}
        </nav>
      </Navbar>
      {isLoggedIn() ? (
        <>
          <main>{children}</main>
        </>
      ) : (
        <>
          <Redirect to="/login" noThrow />
        </>
      )}
      <footer className="bg-dark text-center text-light">
        {` © ${new Date().getFullYear()}, Built with ${(
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        )}`}
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
